exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-cafe-js": () => import("./../../../src/pages/cafe.js" /* webpackChunkName: "component---src-pages-cafe-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-functions-js": () => import("./../../../src/pages/functions.js" /* webpackChunkName: "component---src-pages-functions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */)
}

